.player-wrapper {
  object-fit: contain;
  min-height: 50vh;
  max-height: 50vh;
  margin-top: 46px;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
:root {
  --plyr-color-main: #e28c12;
}
